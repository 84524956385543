jQuery(function ($) {
    var uaMatch = navigator.userAgent.match(/(?:MSIE |Trident\/.*; rv:)(\d+)/);
    var ieVersion = uaMatch ? parseInt(uaMatch[1]) : null;
    var needsAdjustment = ieVersion && ieVersion <= 11;

    if (!(
        Modernizr.audio &&
        Modernizr.borderradius &&
        Modernizr.boxshadow &&
        Modernizr.cssanimations &&
        Modernizr.csstransitions &&
        (Modernizr.flexbox || ieVersion === 10) &&
        Modernizr.fontface &&
        Modernizr.history &&
        Modernizr.opacity &&
        Modernizr.textshadow
    )) {
        $('#outdated-message').show();
    }

    function adjustBodyHeight()
    {
        $('body').height('auto');
        setTimeout(function () {
            $('body').height($('#wrapper').outerHeight(true) + $('#footer').height());
        }, 10);
    }

    function scrollTo(element)
    {
        $('html, body').stop().animate({scrollTop: ($(element).offset().top - ($('#main-menu').outerHeight() + 18))});
    }

    var bookmarkLink = $('h1 a');
    var mainMenu = $('#main-menu');
    var mainMenuElements = mainMenu.find('.item');
    var wrapper = $('#wrapper');

    function selectSection(section) {
        mainMenuElements.removeClass('selected');
        mainMenu.find('.item[data-section="' + section + '"]').addClass('selected');
        wrapper.find('> .section').hide();
        wrapper.find('> .section.' + section).show();
        document.title = 'Oliver Dey · ' + section.substr(0, 1).toUpperCase() + section.substr(1);
        bookmarkLink.text(document.title);
        bookmarkLink.attr('href', '/' + section);

        if (needsAdjustment) {
            adjustBodyHeight();
        }
    }

    function onMainMenuElementClick(event) {
        event.preventDefault();
        var section = $(event.currentTarget).attr('data-section');
        selectSection(section);
        window.history.pushState({section: section}, section, section);
    }

    window.onpopstate = function (event) {
        var section = 'home';
        if (event.state && !!event.state.section) {
            section = event.state.section;
        }
        selectSection(section);
    };

    var match = window.location.href.match(/.*\/(.*?)(\?|#|$)/);
    if (match) {
        if ($.inArray(match[1], ['home', 'music', 'contact']) !== -1) {
            selectSection(match[1]);
        } else if (match[1] === '') {
            window.location.href = '/home';
            return;
        }
    } else {
        return;
    }

    if (needsAdjustment) {
        $(window).on('resize', adjustBodyHeight);
        adjustBodyHeight();
    }

    mainMenuElements.on('click', onMainMenuElementClick);

    //////////////////////////////////

    var modal = $('<div class="modal"></div>');
    $(window.document.body).append(modal);

    function showModal() {
        modal.fadeTo(150, 0.5);
    }

    function hideModal() {
        modal.stop().fadeTo(150, 0, function () {
            modal.hide();
        });
    }

    //////////////////////////////////

    var contactForm = $('#contact-form');
    var contactFormMessage = contactForm.find('> .response-message');
    var submitButton = contactForm.find('input[type="submit"]');

    function setFormResponseMessage(message, type) {
        contactFormMessage.text(message);
        contactFormMessage.addClass(type);
        scrollTo(contactFormMessage);
    }

    function resetForm() {
        submitButton.removeClass('active busy');
        contactFormMessage.text('');
        contactFormMessage.removeClass('error success');

        contactForm.find('.input').each(function () {
            $(this).removeClass('invalid');
            $(this).find('> .element').removeClass('invalid');
            $(this).find('> .input-error').remove();
        });
    }

    contactForm.on('submit', function (event) {
        event.preventDefault();

        showModal();
        resetForm();
        submitButton.addClass('active busy');

        $.post('/contact.php', contactForm.serialize())
            .done(function (response) {
                if (!!response.type) {
                    switch (response.type) {
                        case 'success':
                            setFormResponseMessage(
                                'Your message was sent successfully and will be answered as soon as possible...',
                                'success'
                            );
                            break;

                        case 'error':
                            if (response.validationErrors) {
                                setFormResponseMessage(
                                    'Your message could not be sent, please correct possible input errors and try it again.',
                                    'error'
                                );

                                $.each(response.validationErrors, function (field, errors) {
                                    var input = contactForm.find('.input.' + field);
                                    input.addClass('invalid');
                                    input.find('> .element').addClass('invalid');
                                    input.find('> .error').remove();
                                    $.each(errors, function (index, error) {
                                        input.append('<p class="input-error">' + error + '</p>');
                                    });
                                });
                            } else {
                                setFormResponseMessage(
                                    'Your message could not be sent, please try it again.',
                                    'error'
                                );
                            }
                            break;
                    }
                } else {
                    setFormResponseMessage(
                        'Your message could not be sent, please try it again.',
                        'error'
                    );
                }
            })
            .fail(function () {
                setFormResponseMessage(
                    'Your message could not be sent, please try it again.',
                    'error'
                );
            })
            .always(function () {
                hideModal();
                submitButton.removeClass('active busy');
                contactForm.removeClass('validating');
            });
    });

    submitButton.on('click', function () {
        contactForm.addClass('validating');
    });
});